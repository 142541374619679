import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Flex, Popconfirm, Row, Tag, Typography } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsEventsTagsSelector } from './selectors.ts'
import { LoadingStep } from '../../components/LoadingStep'
import { actions } from './slice.ts'
import { useDispatch } from 'react-redux'
import EventTagWizardModal from '../../components/EventTagWizardModal'
import type { ITag } from './types.ts'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'

const { Title } = Typography

export default function SettingsEventsTags(): ReactElement {
    const [selectedTag, setSelectedTag] = useState<ITag | undefined>()

    const { tags, isFetching } = useAppSelector(settingsEventsTagsSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <>
            <Row justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Темы предложений'}
                </Title>
            </Row>
            {tags.length === 0 && isFetching ? (
                <LoadingStep />
            ) : (
                <Flex gap={'4px 0'} wrap={'wrap'} style={{ marginTop: 20 }}>
                    {tags.map(tag => (
                        <Tag
                            key={tag.event_tag_id}
                            style={{
                                background: 'rgb(255, 87, 166)',
                                border: 'none',
                                color: '#FFF'
                            }}
                            onClose={e => {
                                e.preventDefault()
                            }}
                        >
                            {tag.title}
                            <EditOutlined
                                onClick={() => {
                                    setSelectedTag(tag)
                                    dispatch(actions.toggleTagWizardModal(true))
                                }}
                                style={{
                                    marginLeft: 5,
                                    cursor: 'pointer',
                                    outline: 0
                                }}
                            />
                            <Popconfirm
                                placement={'topLeft'}
                                title={'Вы уверены, что хотите удалить тег?'}
                                description={
                                    'Важна последовательность тегов, если удалить, некоторые страницы будут отдавать 404'
                                }
                                okText={'Да'}
                                cancelText={'Нет'}
                                onConfirm={() =>
                                    dispatch(
                                        actions.removeTag({
                                            post_tag_id: tag.event_tag_id
                                        })
                                    )
                                }
                            >
                                <CloseOutlined style={{ color: '#FFF' }} />
                            </Popconfirm>
                        </Tag>
                    ))}
                    <Tag
                        style={{
                            height: 22,
                            borderStyle: 'dashed',
                            cursor: 'pointer',
                            background: 'rgba(255, 255, 255, 0.5)'
                        }}
                        icon={<PlusOutlined />}
                        onClick={() =>
                            dispatch(actions.toggleTagWizardModal(true))
                        }
                    >
                        {'Новый тег'}
                    </Tag>
                </Flex>
            )}
            <EventTagWizardModal
                selectedTag={selectedTag}
                onAfterClose={() => {
                    setSelectedTag(undefined)
                }}
            />
        </>
    )
}
