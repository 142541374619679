import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { TreeSelect } from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { companyTagsSelector } from './selectors.ts'
import { tagsCompaniesCategories } from '../../utils/consts.ts'

const { SHOW_PARENT } = TreeSelect

interface Props {
    selectedTags: string[]
    onChange: (tags: string[]) => void
}

export default function CompanyTagsSelector({
    selectedTags,
    onChange
}: Props): ReactElement {
    const dispatch = useDispatch()

    const { tags, isFetching } = useAppSelector(companyTagsSelector)

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <TreeSelect
            value={isFetching ? undefined : selectedTags}
            placeholder={'Выберите темы категории'}
            allowClear={true}
            style={{ width: 350 }}
            disabled={isFetching}
            loading={isFetching}
            treeData={tagsCompaniesCategories
                .map((category, index) => ({
                    title: category.title,
                    value: category.categoryId,
                    key: `${index}`,
                    checkable: false,
                    children: tags
                        .filter(
                            tag =>
                                tag.company_category_id === category.categoryId
                        )
                        .map((tag, tagIndex) => ({
                            title: tag.title,
                            value: tag.company_tag_id,
                            key: `${index}-${tagIndex}`
                        }))
                }))
                .filter(category => category.children.length > 0)}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            onChange={onChange}
        />
    )
}
