import type { ReactElement } from 'react'
import { Button, Checkbox, Col, Collapse, Form, Row, Space } from 'antd'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useEffect, useMemo, useState } from 'react'
import { actions } from '../../slice'
import { LoadingStep } from '../../../../components/LoadingStep'
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    FileUnknownOutlined,
    PlusOutlined
} from '@ant-design/icons'
import NextStepButton from '../../../../components/NextStepButton'
import { checkAdminRole, checkFranchiseeRole } from '../../../../utils/helpers'
import CompanySelector from '../../../CompanySelector'
import { useForm } from 'antd/es/form/Form'
import { eventWizardSelector } from '../../selectors'
import { authorizationSelector } from '../../../Authorization/selectors.ts'
import { useSearchParams } from 'react-router-dom'

interface IPlace {
    key: string
    label: string
    children: {
        company_id: string
        title: string
        type: 'online' | 'offline'
    }[]
}

interface ICompanyList {
    company_id: string
    label: string
}

export default function PlaceCompanyStep(): ReactElement {
    const [selectedCompany, setSelectedCompany] = useState<{
        value: string
        label: string
    } | null>(null)
    const [listCompanies, setListCompanies] = useState<ICompanyList[]>([])
    const [places, setPlaces] = useState<IPlace[]>([])
    const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
    const [prevSelectedCompanies, setPrevSelectedCompanies] = useState<
        string[]
    >([])
    const [activeKey, setActiveKey] = useState('')

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()

    const previousPlace = searchParams.get('place')

    const [form] = useForm()

    const placesMemo = useMemo(
        () =>
            places.map(place => ({
                ...place,
                children: (
                    <Space direction={'vertical'}>
                        {place.children.map(address => (
                            <Checkbox
                                key={address.company_id}
                                checked={selectedCompanies.includes(
                                    address.company_id
                                )}
                                onClick={() => {
                                    if (
                                        selectedCompanies.includes(
                                            address.company_id
                                        )
                                    ) {
                                        setSelectedCompanies(prev =>
                                            prev.filter(
                                                id => id !== address.company_id
                                            )
                                        )
                                    } else {
                                        setSelectedCompanies(prev => [
                                            ...prev,
                                            address.company_id
                                        ])
                                    }
                                }}
                            >
                                {address.title}
                                <span
                                    style={{
                                        color:
                                            address.type === 'online'
                                                ? 'green'
                                                : 'rgba(0, 0, 0, 0.45)',
                                        marginLeft: 3
                                    }}
                                >
                                    {' ('}
                                    {address.type === 'online'
                                        ? 'Онлайн'
                                        : 'Оффлайн'}
                                    {')'}
                                </span>
                            </Checkbox>
                        ))}
                    </Space>
                )
            })),
        [places, selectedCompanies]
    )

    const {
        step,
        hasStepChanges,
        addresses,
        currentEvent,
        preCreateData,
        placeCompanies,
        isFetchingPlaceCompanies,
        isFetchingAddress,
        isSaving
    } = useAppSelector(eventWizardSelector)

    const { profile } = useAppSelector(authorizationSelector)

    const isAdmin = checkAdminRole(profile) || checkFranchiseeRole(profile)

    useEffect(() => {
        if (currentEvent) {
            const hasChanges = !prevSelectedCompanies.every(
                (item, index) => selectedCompanies[index] === item
            )
            dispatch(actions.setHasStepChanges(hasChanges))
        }
    }, [currentEvent, selectedCompanies, prevSelectedCompanies])

    const handleUndoChanges = () => {
        if (Object.keys(addresses).length > 0 && isAdmin) {
            const results: IPlace[] = places

            for (const company in addresses) {
                if (
                    places.some(place =>
                        place.children.some(
                            child => child.company_id === company
                        )
                    )
                ) {
                    continue
                }

                results.push({
                    key: company,
                    label: addresses[company][0].name,
                    children: addresses[company].map(address => {
                        const title = [
                            address.additional_text_address,
                            address.text_address
                        ]
                            .filter(Boolean)
                            .join(',')
                        return {
                            company_id: address.company_id,
                            title,
                            type: address.type
                        }
                    })
                })
            }

            setPlaces([...results])
        }
    }

    useEffect(() => {
        handleUndoChanges()
    }, [addresses])

    useEffect(() => {
        if (listCompanies.length > 0) {
            for (const item of listCompanies.filter(
                company => !addresses[company.company_id]
            )) {
                dispatch(
                    actions.fetchAddress({
                        company_id: item.company_id
                    })
                )
            }
        }
    }, [listCompanies])

    useEffect(() => {
        if (currentEvent && Array.isArray(currentEvent.companies)) {
            if (isAdmin && currentEvent.companies[0]) {
                const currentCompany = currentEvent.companies[0]

                setListCompanies([
                    {
                        company_id: currentCompany.company_id,
                        label: currentCompany.name
                    }
                ])
                setSelectedCompanies(
                    currentEvent.companies.map(item => item.company_id)
                )
                setPrevSelectedCompanies(
                    currentEvent.companies.map(item => item.company_id)
                )
                setActiveKey(currentCompany.company_id)
            } else {
                const findPlace = places.find(place =>
                    place.children.some(child =>
                        currentEvent.companies.some(
                            company => company.company_id === child.company_id
                        )
                    )
                )

                setActiveKey(findPlace?.key || '')
                setSelectedCompanies(
                    currentEvent.companies.map(company => company.company_id)
                )
                setPrevSelectedCompanies(
                    currentEvent.companies.map(company => company.company_id)
                )
            }
        } else if (Array.isArray(preCreateData.companies)) {
            if (isAdmin) {
                const companies: ICompanyList[] = []

                preCreateData.companies.forEach(company_id =>
                    companies.push({
                        label: 'Компания',
                        company_id
                    })
                )

                setListCompanies(companies)

                if (companies.length > 0) {
                    setSelectedCompanies(
                        preCreateData.companies.map(item => item)
                    )
                    setPrevSelectedCompanies(
                        preCreateData.companies.map(item => item)
                    )
                    setActiveKey(companies[0].company_id)
                }
            } else {
                const findPlace = places.find(place =>
                    place.children.some(child =>
                        preCreateData.companies?.includes(child.company_id)
                    )
                )

                setActiveKey(findPlace?.key || '')
                setSelectedCompanies(preCreateData.companies)
                setPrevSelectedCompanies(preCreateData.companies)
            }
        }
    }, [currentEvent, places])

    useEffect(() => {
        if (placeCompanies.length === 0 && !isAdmin) {
            dispatch(actions.fetchPlaceCompanies())
        }
    }, [])

    useEffect(() => {
        if (placeCompanies.length > 0) {
            const result: IPlace[] = []

            for (const company of placeCompanies) {
                const title = [
                    company.additional_text_address,
                    company.text_address
                ]
                    .filter(Boolean)
                    .join(', ')

                const addresses = [
                    {
                        company_id: company.company_id,
                        title,
                        type: company.type
                    }
                ]

                if (
                    Array.isArray(company.children) &&
                    company.children.length > 0
                ) {
                    for (const children of company.children) {
                        const title = [
                            children.additional_text_address,
                            children.text_address
                        ]
                            .filter(Boolean)
                            .join(', ')

                        addresses.push({
                            company_id: children.company_id,
                            title,
                            type: children.type
                        })
                    }
                }

                result.push({
                    key: company.company_id,
                    label: company.name,
                    children: addresses
                })
            }

            setPlaces(result)
        }
    }, [placeCompanies])

    const handleSelectPlace = () => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        companies: selectedCompanies
                    }
                })
            )
        } else {
            dispatch(actions.setStep(step + 1))
            dispatch(actions.setParam({ companies: selectedCompanies }))
        }
    }

    useEffect(() => {
        if (previousPlace) {
            const place = previousPlace.split('{d}')
            if (place.length === 2) {
                setListCompanies([
                    {
                        label: place[0],
                        company_id: place[1]
                    }
                ])
                setActiveKey(place[1])
            }
        }
    }, [])

    const handleAddCompany = () => {
        if (
            selectedCompany &&
            !listCompanies.some(
                list => list.company_id === selectedCompany.value
            )
        ) {
            setListCompanies(prev => [
                ...prev,
                {
                    label: selectedCompany.label,
                    company_id: selectedCompany.value
                }
            ])
            setSelectedCompany(null)
            form.resetFields()
        }
    }

    return (
        <>
            {isAdmin ? (
                <Form
                    form={form}
                    layout={'horizontal'}
                    onFinish={handleAddCompany}
                >
                    <Row gutter={10}>
                        <Col span={19}>
                            <Form.Item
                                style={{ width: '100%' }}
                                name={'company'}
                            >
                                <CompanySelector
                                    onChange={company => {
                                        if (!company) {
                                            setSelectedCompany(null)
                                            return
                                        }
                                        const [label, value] =
                                            company.split('{d}')
                                        setSelectedCompany({ label, value })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Button
                                style={{ width: '100%' }}
                                disabled={!selectedCompany}
                                htmlType={'submit'}
                                loading={isFetchingAddress}
                            >
                                {'Добавить компанию'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            ) : null}
            {isFetchingPlaceCompanies || isFetchingAddress ? (
                <LoadingStep />
            ) : placesMemo.length === 0 ? (
                <>
                    <div style={{ textAlign: 'center', margin: '40px 0' }}>
                        {isAdmin ? (
                            <PlusOutlined style={{ fontSize: 23 }} />
                        ) : (
                            <FileUnknownOutlined style={{ fontSize: 23 }} />
                        )}
                        <p>
                            {isAdmin
                                ? 'Необходимо добавить компанию'
                                : 'Компании не найдены'}
                        </p>
                    </div>
                </>
            ) : (
                <Collapse
                    accordion
                    activeKey={activeKey}
                    style={{ marginTop: 20 }}
                    onChange={key => {
                        setSelectedCompanies([])
                        setActiveKey(key[0] ?? '')
                    }}
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : (
                            <CheckCircleOutlined style={{ color: 'gray' }} />
                        )
                    }
                    items={placesMemo}
                />
            )}
            <NextStepButton
                disabled={
                    isFetchingPlaceCompanies ||
                    selectedCompanies.length === 0 ||
                    isFetchingAddress ||
                    (!!currentEvent && !hasStepChanges)
                }
                loading={isSaving}
                onClick={handleSelectPlace}
                showReturnChangesButton={hasStepChanges}
                onReturnChanges={handleUndoChanges}
            >
                {currentEvent ? 'Сохранить' : 'Выбрать и продолжить'}
            </NextStepButton>
        </>
    )
}
