import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import type { TabsProps } from 'antd'
import { Flex } from 'antd'
import { Button } from 'antd'
import { Tabs, Typography } from 'antd'
import EventTagsSelector from '../../../EventTagsSelector'
import { TagsOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../../../hooks/useAppSelector.ts'
import { eventWizardSelector } from '../../selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice.ts'

const { Title } = Typography

export default function AdminSettingsStep(): ReactElement {
    const [tab, setTab] = useState('0')
    const [selectedTags, setSelectedTags] = useState<string[]>([])

    const { currentEvent, isSaving } = useAppSelector(eventWizardSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentEvent) {
            setSelectedTags(
                currentEvent.event_tags.map(tag => tag.event_tag_id)
            )
        }
    }, [currentEvent])

    const items: TabsProps['items'] = [
        {
            key: '0',
            icon: <TagsOutlined />,
            label: 'Темы категории',
            children: (
                <EventTagsSelector
                    selectedTags={selectedTags}
                    onChange={setSelectedTags}
                />
            )
        }
    ]

    const handleSaveTags = (): void => {
        if (currentEvent) {
            dispatch(
                actions.saveEvent({
                    event_id: currentEvent.event_id,
                    form: {
                        event_tags: selectedTags
                    }
                })
            )
        }
    }

    return (
        <>
            <Title level={5} style={{ margin: 0 }}>
                {'Настройки администратора'}
            </Title>
            <Tabs
                defaultActiveKey={tab}
                items={items}
                onChange={setTab}
                style={{ marginBottom: 20 }}
            />
            <Flex>
                <Button
                    style={{ marginLeft: 'auto' }}
                    type={'primary'}
                    loading={isSaving}
                    onClick={handleSaveTags}
                >
                    {'Сохранить изменения'}
                </Button>
            </Flex>
        </>
    )
}
