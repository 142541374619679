import type { ReactElement, ReactNode, FC } from 'react'
import { useEffect, useState, memo, useRef } from 'react'
import {
    Affix,
    Anchor,
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    notification,
    Row,
    Segmented,
    Select,
    Space,
    Switch,
    Table,
    Typography,
    InputNumber,
    Spin,
    Tree,
    Checkbox,
    Upload,
    Modal,
    message,
    Statistic
} from 'antd'
import { Link, useBlocker, useLocation, useNavigate } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocation } from '@fortawesome/free-solid-svg-icons'
import {
    ArrowLeftOutlined,
    CheckOutlined,
    DeleteOutlined,
    DownCircleOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    UpCircleOutlined
} from '@ant-design/icons'
import AreaSelector from '../AreaSelector'
import AdBannerModal from '../../components/AdBannerModal'
import ImgCrop from 'antd-img-crop'
import copy from 'copy-text-to-clipboard'
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import { IMask, IMaskInput } from 'react-imask'
import {
    advertisingStatus,
    advertisingStatusColor,
    bannerTypes,
    getRole,
    hasTreeChildrenChecked,
    regExpTime,
    weekdaysTemplate
} from '../../utils/helpers.ts'
import UserSelector from '../UserSelector'
import FranchiseeSelector from '../FranchiseeSelector'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { authorizationSelector } from '../Authorization/selectors.ts'
import { advertisingWizardSelector } from './selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import type { ICategory, IForm } from './types.ts'
import { useForm } from 'antd/es/form/Form'
import SelectedTreeParent from '../../components/SelectedTreeParent'
import type { Key } from 'antd/es/table/interface'
import type { DataNode } from 'antd/es/tree'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import classNames from 'classnames'
import ModalAdvertisingRadiusModal from '../../components/ModalAdvertisingRadiusModal'
import MyCompaniesSelector from '../MyCompaniesSelector'
import Collapse from '@kunukn/react-collapse'
import BannersBlock from '../BannersBlock'
import { useDebounce } from '../../hooks/useDebounce.ts'
import type { LazyLoadImageProps } from 'react-lazy-load-image-component'

const TypedLazyLoadImage = LazyLoadImage as FC<LazyLoadImageProps>

const { Title, Text, Paragraph } = Typography
const { RangePicker } = DatePicker
const { TextArea } = Input

const MemoBannersBlock = memo(
    BannersBlock,
    (prevProps, nextProps) => prevProps.code === nextProps.code
)

interface Props {
    isCreate?: boolean
}

interface DataType {
    area_id: string
    name: string
    onRemove: (indexRemove: number) => void
}

interface ITreeItem {
    title: string
    key: string
    icon?: ReactElement
    children?: ITreeItem[]
}

const columns: ColumnsType<DataType> = [
    {
        title: '',
        dataIndex: '',
        key: 'icon',
        align: 'center',
        render: () => <FontAwesomeIcon icon={faMapLocation} color={'#FF9D05'} />
    },
    {
        title: 'Регион',
        dataIndex: 'name',
        key: 'name',
        width: '100%'
    },
    {
        title: '',
        dataIndex: '',
        key: 'remove',
        render: (data, _, index) => (
            <Button danger onClick={() => data.onRemove(index)}>
                <DeleteOutlined />
            </Button>
        )
    }
]

const isOpenExternalLinkCompany = true

export default function AdvertisingWizard({ isCreate }: Props): ReactElement {
    const [distance, setDistance] = useState<'region' | 'radius'>('region')
    const [isRegionModal, setIsRegionModal] = useState(false)
    const [regions, setRegions] = useState<DataType[]>([])
    const [selectedRadius, setSelectedRadius] = useState(1)
    const [isModalRadius, setIsModalRadius] = useState(false)
    const [isModalAdBanner, setIsModalAdBanner] = useState(false)
    const [weekdays, setWeekdays] = useState(weekdaysTemplate)
    const [isAroundTheClock, setIsAroundTheClock] = useState(false)

    const debounceSelectedRadius = useDebounce(selectedRadius, 600)

    const [treeData, setTreeData] = useState<DataNode[]>([])
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
    const [selectedCategoriesOnline, setSelectedCategoriesOnline] = useState<
        Key[]
    >([])
    const [selectedCategoriesOffline, setSelectedCategoriesOffline] = useState<
        Key[]
    >([])
    const [address, setAddress] = useState({
        address: '',
        lat: 0,
        lng: 0
    })
    const [typeUrl, setTypeUrl] = useState<'company' | 'url'>('company')
    const [companySelector, setCompanySelector] = useState('')
    const [isShowRegisterAd, setIsShowRegisterAd] = useState(false)

    const [isModalExit, setIsModalExit] = useState(false)
    const [disabledBlocker, setDisabledBlocker] = useState(false)

    const [tempData, setTempData] = useState<Record<string, unknown>>({})

    const location = useLocation()

    const { profile } = useAppSelector(authorizationSelector)
    const {
        currentAdvertisingCompany,
        isProcess,
        categories,
        banners,
        coverage,
        isFetchingCategories,
        isCreateNormalBanner,
        isCreateInfoModal,
        isProcessCoverage
    } = useAppSelector(advertisingWizardSelector)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [form] = useForm()

    const titleWatch = Form.useWatch('title', form)
    const urlWatch = Form.useWatch('url', form)
    const emailWatch = Form.useWatch('email', form)
    const eridWatch = Form.useWatch('erid', form)
    const signatureWatch = Form.useWatch('signature', form)
    const periodWatch = Form.useWatch('period', form)
    const audienceInterestWatch = Form.useWatch('audience_interest', form)
    const statusWatch = Form.useWatch('status', form)
    const clickCostWatch = Form.useWatch('click_cost', form)
    const noteWatch = Form.useWatch('note', form)
    const userIdWatch = Form.useWatch('user_id', form)
    const franchiseeIdWatch = Form.useWatch('franchisee_id', form)
    const paymentStrategyWatch = Form.useWatch('payment_strategy', form)

    const isDistanceFilled =
        distance === 'region'
            ? regions.length > 0
            : selectedRadius > 0 && address.address
    const isScheduleFilled = weekdays.some(item => item.isOpen)

    const selectedCategories =
        audienceInterestWatch === 'online'
            ? selectedCategoriesOnline
            : selectedCategoriesOffline

    const isCategoriesFilled = selectedCategories.length > 0

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : false

    const isAdminOrFranchisee = profile
        ? isAdmin || getRole(profile.roles) === 'franchisee'
        : false

    const isDraft =
        (typeUrl === 'url'
            ? isAdminOrFranchisee
                ? !urlWatch
                : !urlWatch || !emailWatch
            : !companySelector) ||
        !isDistanceFilled ||
        (!isScheduleFilled && !isAroundTheClock) ||
        !isCategoriesFilled ||
        !periodWatch ||
        banners.length === 0

    const isNeedRegisterAd = isAdminOrFranchisee
        ? typeUrl === 'url' &&
          !eridWatch &&
          !signatureWatch &&
          statusWatch === '3'
        : false

    useEffect(() => {
        if (isDistanceFilled) {
            if (distance === 'region') {
                dispatch(
                    actions.fetchCoverage({
                        form: {
                            areas: regions.map(region => region.area_id)
                        }
                    })
                )
            } else {
                dispatch(
                    actions.fetchCoverage({
                        form: {
                            radius: debounceSelectedRadius,
                            lat: address.lat,
                            lng: address.lng
                        }
                    })
                )
            }
        }
    }, [isDistanceFilled, regions, distance, address, debounceSelectedRadius])

    const checkChangedFields = (
        checkOnlyPaymentStrategy?: boolean
    ): boolean => {
        if (!isCreate && currentAdvertisingCompany) {
            let additionalCheck = false
            if (isAdminOrFranchisee) {
                const selectorsKeys = {
                    user_id: userIdWatch,
                    franchisee_id: franchiseeIdWatch
                }

                let selectorsCheck = false

                for (const [key, value] of Object.entries(selectorsKeys)) {
                    if (tempData[key] && value) {
                        const first = (tempData[key] as string)?.split('{d}')[1]
                        const second = value?.split('{d}')[1]
                        selectorsCheck = first !== second
                    } else {
                        selectorsCheck = (tempData[key] as string) !== value
                    }
                    if (selectorsCheck) {
                        break
                    }
                }

                additionalCheck =
                    tempData.status !== statusWatch ||
                    tempData.click_cost !== clickCostWatch ||
                    tempData.note !== noteWatch ||
                    selectorsCheck
            }

            let checkPeriod = tempData.period !== periodWatch

            if (Array.isArray(tempData.period) && Array.isArray(periodWatch)) {
                checkPeriod = !tempData.period.every(
                    (date, index) =>
                        date.toString() === periodWatch[index].toString()
                )
            }

            let stringCheck = false

            const stringValues = {
                title: titleWatch,
                url: urlWatch,
                email: emailWatch,
                erid: eridWatch,
                signature: signatureWatch
            }

            for (const [key, value] of Object.entries(stringValues)) {
                if (key && value) {
                    stringCheck =
                        tempData[key as keyof typeof stringValues] !== value
                } else {
                    stringCheck =
                        !!tempData[key as keyof typeof stringValues] !== !!value
                }
                if (stringCheck) {
                    break
                }
            }

            const isPaymentStrategyChanged =
                tempData.payment_strategy !== paymentStrategyWatch

            const fullCheck =
                stringCheck ||
                tempData.audience_interest !== audienceInterestWatch ||
                additionalCheck ||
                tempData.geo_type !== distance ||
                tempData.radius !== selectedRadius ||
                tempData.around_the_clock !== isAroundTheClock ||
                tempData.type !== typeUrl ||
                tempData.weekdays !== JSON.stringify(weekdays) ||
                (tempData.geo_type === 'radius'
                    ? tempData.areas !== JSON.stringify(regions)
                    : false) ||
                tempData.selectedAddress !== JSON.stringify(address) ||
                checkPeriod ||
                tempData.selectedCategories !==
                    JSON.stringify(selectedCategories) ||
                tempData.banners !== JSON.stringify(banners)

            if (checkOnlyPaymentStrategy) {
                return isPaymentStrategyChanged && !fullCheck
            }

            return (isPaymentStrategyChanged || fullCheck) && !disabledBlocker
        }
        return false
    }

    const blocker = useBlocker((): boolean => {
        if (!isCreate && currentAdvertisingCompany) {
            return checkChangedFields()
        }
        return false
    })

    useEffect(() => {
        if (blocker.state === 'blocked') {
            setIsModalExit(true)
        }
    }, [blocker])

    const setSelectedCategories =
        audienceInterestWatch === 'online'
            ? setSelectedCategoriesOnline
            : setSelectedCategoriesOffline

    const onRemove = (indexRemove: number): void => {
        setRegions(prev => prev.filter((_, index) => index !== indexRemove))
    }

    useEffect(() => {
        dispatch(actions.fetchCategories())

        return () => {
            notification.destroy()
        }
    }, [])

    useEffect(() => {
        if (profile) {
            if (isAdminOrFranchisee) {
                form.setFieldValue(
                    'user_id',
                    `${profile.full_name || profile.phone || profile.email}{d}${profile.user_id}`
                )
            }
        }
    }, [profile])

    useEffect(() => {
        if (audienceInterestWatch && categories.length > 0) {
            const deepMap = (category: ICategory): ITreeItem => {
                return {
                    title: category.name,
                    key: category.companyCategoryId,
                    icon: category.icoUrl ? (
                        <img height={12} src={category.icoUrl} />
                    ) : undefined,
                    children: category.children
                        ? category.children.map(deepMap)
                        : undefined
                }
            }

            setTreeData(
                categories.map(deepMap)[
                    audienceInterestWatch === 'online' ? 0 : 1
                ].children as ITreeItem[]
            )
        }
    }, [categories, audienceInterestWatch])

    useEffect(() => {
        if (!isCreate && currentAdvertisingCompany) {
            const {
                user,
                franchisee,
                timetables,
                areas,
                banners,
                company_categories,
                address: addressData,
                lat,
                lng,
                around_the_clock,
                type,
                email,
                erid,
                signature
            } = currentAdvertisingCompany

            const currentPrice =
                currentAdvertisingCompany.payment_strategy === 'click'
                    ? currentAdvertisingCompany.click_cost
                    : currentAdvertisingCompany.view_cost

            const result: Record<string, unknown> = {
                title: currentAdvertisingCompany.title,
                url: type === 'url' ? currentAdvertisingCompany.url : undefined,
                email: type === 'url' ? email : undefined,
                erid,
                signature,
                period:
                    currentAdvertisingCompany.started_at &&
                    currentAdvertisingCompany.finished_at
                        ? [
                              dayjs(currentAdvertisingCompany.started_at),
                              dayjs(currentAdvertisingCompany.finished_at)
                          ]
                        : undefined,
                audience_interest: isAdminOrFranchisee
                    ? currentAdvertisingCompany.audience_interest
                    : 'offline',
                status: currentAdvertisingCompany.status.toString(),
                payment_strategy: currentAdvertisingCompany.payment_strategy,
                click_cost: currentPrice === null ? '35' : currentPrice / 100,
                view_cost: currentPrice === null ? '35' : currentPrice / 100,
                note: currentAdvertisingCompany.note,
                admin_message: currentAdvertisingCompany.admin_message,
                user_id: user
                    ? `${[user.full_name, user.email, user.phone].filter(Boolean).join(' - ')}{d}${user.user_id}`
                    : undefined,
                franchisee_id: franchisee
                    ? `${franchisee.name}{d}${franchisee.franchisee_id}`
                    : undefined
            }

            if (
                !isAdminOrFranchisee &&
                currentAdvertisingCompany.admin_message
            ) {
                notification.destroy()
                notification.open({
                    type: 'warning',
                    message: 'Сообщение от администратора',
                    description: currentAdvertisingCompany.admin_message,
                    placement: 'bottomRight',
                    duration: null
                })
            }

            setDistance(currentAdvertisingCompany.geo_type)
            setSelectedRadius(currentAdvertisingCompany.radius || 1)
            setIsAroundTheClock(!!around_the_clock)
            setTypeUrl(type)
            if (type === 'company') {
                setCompanySelector(
                    `${currentAdvertisingCompany.url}{d}${currentAdvertisingCompany.url}`
                )
            }
            let selectedAddress = address

            if (profile && profile.homeAddress) {
                const {
                    address,
                    location: { lat, lng }
                } = profile.homeAddress
                selectedAddress = {
                    address,
                    lat,
                    lng
                }
            }

            if (
                currentAdvertisingCompany.geo_type === 'radius' &&
                address &&
                lat &&
                lng
            ) {
                selectedAddress = {
                    address: addressData as string,
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                }
            }

            setAddress(selectedAddress)

            const weekdays = weekdaysTemplate.map((day, index) => {
                const findTimeTable = timetables.find(
                    table => table.day_of_week === index.toString()
                )
                if (findTimeTable) {
                    return {
                        ...day,
                        time: [
                            findTimeTable.start_time.substring(0, 5),
                            findTimeTable.finish_time.substring(0, 5)
                        ] as [string, string],
                        isOpen: findTimeTable.is_active === '1'
                    }
                }

                return day
            })

            setWeekdays(weekdays)

            const selectedAreas = areas.map(area => ({
                area_id: area.area_id,
                name: area.name,
                onRemove
            }))
            setRegions(selectedAreas)

            dispatch(actions.initialBanners(banners))

            const setSelectedCategoriesMultiple =
                currentAdvertisingCompany.audience_interest === 'online'
                    ? setSelectedCategoriesOnline
                    : setSelectedCategoriesOffline

            const selectedCategoriesData = company_categories.map(
                category => category.companyCategoryId
            )

            setSelectedCategoriesMultiple(selectedCategoriesData)

            setTempData({
                ...result,
                geo_type: currentAdvertisingCompany.geo_type,
                radius: currentAdvertisingCompany.radius || 1,
                around_the_clock: !!around_the_clock,
                type,
                companySelector: currentAdvertisingCompany.url,
                selectedAddress: JSON.stringify(selectedAddress),
                weekdays: JSON.stringify(weekdays),
                areas: JSON.stringify(selectedAreas),
                selectedCategories: JSON.stringify(selectedCategoriesData),
                banners: JSON.stringify(banners)
            })

            form.setFieldsValue(result)
        }
    }, [currentAdvertisingCompany, isCreate])

    const handleAddRegion = (
        data: { area_id: string; title: string }[]
    ): void => {
        const hasAdded = regions.some(region =>
            data.some(item => item.area_id === region.area_id)
        )

        if (hasAdded) {
            notification.open({
                type: 'error',
                message: 'Ошибка',
                description: 'Регион уже добавлен!',
                placement: 'top'
            })
            return
        }

        setRegions(prev => [
            ...prev,
            ...data.map(item => ({
                area_id: item.area_id,
                name: item.title,
                onRemove
            }))
        ])

        setIsRegionModal(false)
    }

    const handleSendForm = (data: Record<string, string>) => {
        if (isNeedRegisterAd) {
            Modal.warning({
                title: 'Внимание',
                content: (
                    <>
                        {'Необходимо заполнить раздел '}
                        <b>{'Регистрация рекламы'}</b>
                        {', для публикации кампании с '}
                        <b>{'Внешней ссылкой!'}</b>
                    </>
                ),
                width: 480,
                okText: 'Понятно',
                onOk() {}
            })
            return
        }

        setDisabledBlocker(true)

        const selectorKeys: {
            [key: string]: string | undefined
        } = {
            user_id: undefined,
            franchisee_id: undefined
        }

        for (const key in data) {
            if (key in selectorKeys) {
                selectorKeys[key] = data[key]
                    ? data[key].split('{d}')[1]
                    : undefined
            }
        }

        if (!isAdminOrFranchisee) {
            data.status = isDraft ? '1' : '2'
        }

        if (
            distance === 'radius' &&
            (!address.address || !address.lat || !address.lng)
        ) {
            notification.open({
                type: 'error',
                message: 'Ошибка',
                description: 'Вы не указали адрес!',
                placement: 'top'
            })

            return
        }

        if (isAdminOrFranchisee && !selectorKeys.user_id) {
            return
        }

        const form: IForm = {
            title: data.title,
            type: typeUrl,
            url: typeUrl === 'url' ? data.url : companySelector.split('{d}')[0],
            geo_type: distance,
            email: typeUrl === 'url' ? data.email : undefined,
            areas:
                distance === 'region'
                    ? regions.map(item => item.area_id)
                    : undefined,
            radius:
                distance === 'radius' ? selectedRadius.toString() : undefined,
            address: distance === 'radius' ? address.address : undefined,
            lat: distance === 'radius' ? address.lat : undefined,
            lng: distance === 'radius' ? address.lng : undefined,
            erid: data.erid,
            signature: data.signature,
            started_at: data.period
                ? dayjs(data.period[0]).format('YYYY-MM-DD')
                : undefined,
            finished_at: data.period
                ? dayjs(data.period[1]).format('YYYY-MM-DD')
                : undefined,
            around_the_clock: isAroundTheClock ? '1' : '0',
            status: data.status as '1' | '2' | '3' | '4',
            note: data.note,
            admin_message: data.admin_message,
            payment_strategy: data.payment_strategy,
            click_cost: isAdminOrFranchisee
                ? data.click_cost
                    ? (parseInt(data.click_cost) * 100).toString()
                    : '3500'
                : undefined,
            view_cost: isAdminOrFranchisee
                ? data.view_cost
                    ? (parseInt(data.view_cost) * 100).toString()
                    : '3500'
                : undefined,
            audience_interest: isAdminOrFranchisee
                ? (data.audience_interest as 'online' | 'offline')
                : 'offline',
            user_id: selectorKeys.user_id,
            franchisee_id: selectorKeys.franchisee_id,
            timetables: weekdays
                .map((day, index) => ({
                    day_of_week: index,
                    start_time:
                        day.time[0] && day.time[1] ? `${day.time[0]}:00` : '',
                    finish_time:
                        day.time[0] && day.time[1] ? `${day.time[1]}:00` : '',
                    is_active: (day.isOpen ? '1' : '0') as '1' | '0'
                }))
                .filter(day => day.start_time && day.finish_time),
            banners: banners.map(banner => {
                if (banner.type === 'normal') {
                    return {
                        ...banner,
                        normal_banner: {
                            id: banner.normal_banner?.id
                        }
                    }
                }
                return {
                    ...banner,
                    custom_banner: {
                        id: banner.custom_banner?.id
                    }
                }
            }),
            company_categories:
                data.audience_interest === 'online'
                    ? selectedCategoriesOnline.map(categoryId => ({
                          company_category_id: categoryId
                      }))
                    : selectedCategoriesOffline.map(categoryId => ({
                          company_category_id: categoryId
                      }))
        }

        if (isCreate) {
            dispatch(
                actions.create({
                    form,
                    isCreateInfoModal: !isAdminOrFranchisee && !isDraft
                })
            )
        } else {
            if (currentAdvertisingCompany) {
                if (checkChangedFields(true)) {
                    dispatch(
                        actions.edit({
                            advertisingGroupId:
                                currentAdvertisingCompany.advertising_group_id,
                            form,
                            isChangedOnlyPaymentStrategy: true
                        })
                    )
                } else {
                    dispatch(
                        actions.edit({
                            advertisingGroupId:
                                currentAdvertisingCompany.advertising_group_id,
                            form
                        })
                    )
                }
            }
        }
    }

    const expandToggle = (selectedKey: Key) => {
        setExpandedKeys(prev =>
            prev.includes(selectedKey)
                ? prev.filter(key => key !== selectedKey)
                : [...prev, selectedKey]
        )
    }

    const handlePickNormalBanner = (image: File) => {
        dispatch(
            actions.createNormalBanner({
                image
            })
        )
    }

    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                <Col span={8}>
                    <Title level={3} style={{ margin: 0 }}>
                        {isCreate
                            ? 'Создание кампании'
                            : 'Редактирование кампании'}
                    </Title>
                    {!isCreate && currentAdvertisingCompany ? (
                        <Text>
                            {'Статус: '}
                            <Text
                                style={{
                                    color: advertisingStatusColor[
                                        currentAdvertisingCompany.status
                                    ]
                                }}
                                type={'secondary'}
                            >
                                {
                                    advertisingStatus[
                                        currentAdvertisingCompany.status
                                    ]
                                }
                            </Text>
                        </Text>
                    ) : null}
                    <Row>
                        <Link className={styles.link} to={'/advertising'}>
                            <ArrowLeftOutlined
                                style={{ color: '#ff57a5', marginRight: 5 }}
                            />
                            {'Вернуться к списку'}
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 20 }} />
            <Row gutter={[20, 20]}>
                <Col span={4}>
                    <Affix>
                        <div>
                            <Anchor
                                affix={false}
                                bounds={240}
                                targetOffset={50}
                                items={[
                                    {
                                        key: '1',
                                        href: `${location.pathname}#basic`,
                                        title: 'Основные данные'
                                    },
                                    {
                                        key: '2',
                                        href: `${location.pathname}#coverage`,
                                        title: 'Охваты'
                                    },
                                    {
                                        key: '3',
                                        href: `${location.pathname}#views`,
                                        title: 'Настройте показы'
                                    },
                                    {
                                        key: '4',
                                        href: `${location.pathname}#settings`,
                                        title: 'Настройте внешний вид'
                                    },
                                    ...((!isAdminOrFranchisee &&
                                        typeUrl === 'url') ||
                                    isAdminOrFranchisee
                                        ? [
                                              {
                                                  key: '5',
                                                  href: `${location.pathname}#register`,
                                                  title: 'Регистрация рекламы'
                                              }
                                          ]
                                        : []),
                                    {
                                        key: '6',
                                        href: `${location.pathname}#control`,
                                        title: 'Управление'
                                    }
                                ]}
                            />
                            <MemoBannersBlock
                                hideCaption={true}
                                isVertical={true}
                                code={'admin-advertising-wizard'}
                                style={{ marginTop: 120 }}
                            />
                        </div>
                    </Affix>
                </Col>
                <Col span={14}>
                    <Form
                        form={form}
                        layout={'vertical'}
                        initialValues={{
                            click_cost: '35',
                            view_cost: '35',
                            status: '1',
                            audience_interest: 'offline',
                            payment_strategy: 'click'
                        }}
                        onFinish={handleSendForm}
                        autoComplete={'off'}
                    >
                        <div className={styles.block} id={'basic'}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Основные данные'}
                            </Title>
                            <Form.Item
                                name={'title'}
                                label={'Название кампании'}
                                style={{ marginBottom: 10 }}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Заполните название рекламной кампании'
                                    }
                                ]}
                            >
                                <Input placeholder={'Название кампании'} />
                            </Form.Item>
                            {isOpenExternalLinkCompany ? (
                                <Segmented
                                    options={[
                                        {
                                            label: 'Ссылка на компанию',
                                            value: 'company'
                                        },
                                        {
                                            label: 'Внешняя ссылка',
                                            value: 'url'
                                        }
                                    ]}
                                    value={typeUrl}
                                    onChange={value => {
                                        setTypeUrl(value as 'company' | 'url')
                                        setIsShowRegisterAd(value === 'url')
                                    }}
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                        marginBottom: 10
                                    }}
                                />
                            ) : (
                                <Paragraph style={{ marginBottom: 7 }}>
                                    {'Ссылка на компанию'}
                                </Paragraph>
                            )}
                            {typeUrl === 'company' ? (
                                <div style={{ marginBottom: 15 }}>
                                    <MyCompaniesSelector
                                        style={{ width: '45%' }}
                                        initialValue={companySelector}
                                        onChange={setCompanySelector}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Form.Item
                                        name={'url'}
                                        label={
                                            'Ссылка на рекламируемую страницу'
                                        }
                                        style={{ marginBottom: 15 }}
                                        rules={[
                                            {
                                                type: 'url',
                                                message:
                                                    'Рекламируемая страница, должна быть ссылкой'
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder={'https://site.com'}
                                        />
                                    </Form.Item>
                                    {!isAdminOrFranchisee ? (
                                        <Paragraph
                                            style={{
                                                background: '#fdf4f8',
                                                padding: 15,
                                                borderRadius: 15
                                            }}
                                        >
                                            {
                                                'Реклама на внешние ссылки доступна только при оплате по счету на сумму от 50 000 руб. Укажите ниже почту для отправки счета. В случае указания внешней ссылки обязательно получение ERID, ниже вы найдете необходимую информацию.'
                                            }
                                        </Paragraph>
                                    ) : null}
                                    <Form.Item
                                        label={'Почта для выставления счета'}
                                        name={'email'}
                                        rules={[
                                            {
                                                message:
                                                    'Пожалуйста заполните почту'
                                            },
                                            {
                                                type: 'email',
                                                message:
                                                    'Почта введена не верно!'
                                            }
                                        ]}
                                    >
                                        <Input placeholder={'Email'} />
                                    </Form.Item>
                                </div>
                            )}
                            <Segmented
                                onChange={value =>
                                    setDistance(value as 'region' | 'radius')
                                }
                                value={distance}
                                options={[
                                    { label: 'Регион', value: 'region' },
                                    {
                                        label: 'Радиус от адреса',
                                        value: 'radius'
                                    }
                                ]}
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    marginBottom: 10
                                }}
                            />
                            {distance === 'region' ? (
                                <div style={{ marginTop: 5 }}>
                                    <span className={styles.label}>
                                        {'Зона показа рекламы'}
                                    </span>
                                    <Table
                                        dataSource={regions}
                                        columns={columns}
                                        rowKey={record => record.area_id}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 25
                                        }}
                                        pagination={false}
                                        locale={{
                                            emptyText: 'Регионы не добавлены'
                                        }}
                                        footer={() => (
                                            <Row justify={'end'}>
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type={'primary'}
                                                    onClick={() =>
                                                        setIsRegionModal(true)
                                                    }
                                                >
                                                    {'Добавить регион'}
                                                </Button>
                                            </Row>
                                        )}
                                    />
                                </div>
                            ) : (
                                <>
                                    <br />
                                    <Title
                                        style={{ margin: '5px 0 10px' }}
                                        level={5}
                                    >
                                        {address.address
                                            ? `Выбранный радиус: ${selectedRadius} км`
                                            : 'Необходимо выбрать адрес и задать радиус'}
                                    </Title>
                                    {address.address ? (
                                        <>
                                            <Text>{address.address}</Text>
                                            <br />
                                        </>
                                    ) : null}
                                    <Button
                                        type={'primary'}
                                        onClick={() => setIsModalRadius(true)}
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 0
                                        }}
                                    >
                                        {'Выбрать радиус'}
                                    </Button>
                                </>
                            )}
                        </div>
                        <div className={styles.block} id={'coverage'}>
                            <div
                                className={styles.block}
                                style={{
                                    background: '#f5f5f582',
                                    marginBottom: 0
                                }}
                            >
                                <Row justify={'space-between'}>
                                    <Title style={{ marginTop: 0 }} level={4}>
                                        {'Охваты по вашей геолокации'}
                                    </Title>
                                </Row>
                                {isProcessCoverage ? (
                                    <Spin />
                                ) : isDistanceFilled ? (
                                    <Statistic
                                        title={'Количество целевой аудитории'}
                                        value={coverage ?? 0}
                                        groupSeparator={' '}
                                    />
                                ) : (
                                    `Для просмотра охватов необходимо заполнить ${distance === 'region' ? 'регион' : 'радиус от адреса'}.`
                                )}
                            </div>
                        </div>
                        <div className={styles.block} id={'views'}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Настройте показы'}
                            </Title>
                            <Form.Item
                                name={'audience_interest'}
                                label={
                                    <span className={styles.label}>
                                        {'Интересы аудиторий'}
                                    </span>
                                }
                                style={{
                                    marginBottom: isAdminOrFranchisee ? 10 : -30
                                }}
                            >
                                {isAdminOrFranchisee ? (
                                    <Segmented
                                        options={[
                                            {
                                                label: 'Оффлайн',
                                                value: 'offline'
                                            },
                                            {
                                                label: 'Онлайн',
                                                value: 'online'
                                            }
                                        ]}
                                        style={{
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.08)',
                                            marginBottom: 10
                                        }}
                                    />
                                ) : null}
                            </Form.Item>
                            {isFetchingCategories ? (
                                <Spin style={{ marginLeft: 60 }} />
                            ) : (
                                <Tree
                                    expandedKeys={expandedKeys}
                                    showIcon
                                    checkable
                                    checkedKeys={selectedCategories}
                                    switcherIcon={<DownOutlined />}
                                    treeData={treeData.map(item => ({
                                        ...item,
                                        title: hasTreeChildrenChecked(
                                            item,
                                            selectedCategories
                                        ) ? (
                                            <SelectedTreeParent>
                                                {item.title as ReactNode}
                                            </SelectedTreeParent>
                                        ) : (
                                            item.title
                                        )
                                    }))}
                                    onCheck={(value, { node }) => {
                                        if (Array.isArray(value)) {
                                            setSelectedCategories(value)
                                            if (!node.expanded) {
                                                expandToggle(node.key)
                                            }
                                        }
                                    }}
                                    style={{ background: '#FFF' }}
                                    onSelect={(_, { node }) => {
                                        if (
                                            typeof node.checkable !== 'boolean'
                                        ) {
                                            if (
                                                node.children &&
                                                !selectedCategories.includes(
                                                    node.key
                                                )
                                            ) {
                                                setSelectedCategories(prev => [
                                                    ...prev,
                                                    node.key
                                                ])
                                            }
                                        }
                                        if (!expandedKeys.includes(node.key)) {
                                            expandToggle(node.key)
                                        }
                                    }}
                                    onExpand={(_, { node }) =>
                                        expandToggle(node.key)
                                    }
                                />
                            )}
                            <Form.Item
                                name={'period'}
                                label={
                                    <span className={styles.label}>
                                        {'Срок проведения кампании'}
                                    </span>
                                }
                                style={{ marginTop: 20, marginBottom: 10 }}
                            >
                                <RangePicker
                                    style={{ width: '50%', marginBottom: 15 }}
                                    format={'DD.MM.YYYY'}
                                />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span className={styles.label}>
                                        {'Расписание показов'}
                                    </span>
                                }
                                style={{ width: '100%', marginBottom: 10 }}
                            >
                                {weekdays.map((week, index) => {
                                    const refSecondInput =
                                        useRef<HTMLInputElement | null>(null)
                                    return (
                                        <Row
                                            gutter={[16, 16]}
                                            key={week.name}
                                            style={{
                                                width: '100%',
                                                marginBottom: 15
                                            }}
                                            align={'middle'}
                                        >
                                            <Col
                                                style={
                                                    !week.isOpen ||
                                                    isAroundTheClock
                                                        ? {
                                                              opacity: 0.4,
                                                              transition: '0.2s'
                                                          }
                                                        : {
                                                              transition: '0.2s'
                                                          }
                                                }
                                                span={4}
                                            >
                                                {week.name}
                                            </Col>
                                            <Col span={8}>
                                                <Space>
                                                    <IMaskInput
                                                        mask={Date}
                                                        disabled={
                                                            !week.isOpen ||
                                                            isAroundTheClock
                                                        }
                                                        pattern={'HH:mm'}
                                                        blocks={{
                                                            HH: {
                                                                mask: IMask.MaskedRange,
                                                                from: 0,
                                                                to: 23
                                                            },
                                                            mm: {
                                                                mask: IMask.MaskedRange,
                                                                from: 0,
                                                                to: 59
                                                            }
                                                        }}
                                                        format={(
                                                            date: string
                                                        ) =>
                                                            dayjs(date).format(
                                                                'HH:mm'
                                                            )
                                                        }
                                                        parse={(str: string) =>
                                                            dayjs(str, 'HH:mm')
                                                        }
                                                        value={week.time[0]}
                                                        placeholder={'Начало'}
                                                        className={
                                                            'custom-input'
                                                        }
                                                        onInput={event => {
                                                            if (
                                                                regExpTime.test(
                                                                    (
                                                                        event.target as typeof event.target & {
                                                                            value: string
                                                                        }
                                                                    ).value
                                                                )
                                                            ) {
                                                                refSecondInput.current?.focus()
                                                            }
                                                            setWeekdays(prev =>
                                                                prev.map(
                                                                    (
                                                                        prevItem,
                                                                        prevIndex
                                                                    ) => {
                                                                        if (
                                                                            prevIndex ===
                                                                            index
                                                                        ) {
                                                                            return {
                                                                                ...prevItem,
                                                                                time: [
                                                                                    (
                                                                                        event.target as typeof event.target & {
                                                                                            value: string
                                                                                        }
                                                                                    )
                                                                                        .value,
                                                                                    prevItem
                                                                                        .time[1]
                                                                                ]
                                                                            }
                                                                        }
                                                                        return prevItem
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                    />
                                                    <IMaskInput
                                                        mask={Date}
                                                        pattern={'HH:mm'}
                                                        disabled={
                                                            !week.isOpen ||
                                                            isAroundTheClock
                                                        }
                                                        inputRef={
                                                            refSecondInput
                                                        }
                                                        blocks={{
                                                            HH: {
                                                                mask: IMask.MaskedRange,
                                                                from: 0,
                                                                to: 23
                                                            },
                                                            mm: {
                                                                mask: IMask.MaskedRange,
                                                                from: 0,
                                                                to: 59
                                                            }
                                                        }}
                                                        className={
                                                            'custom-input'
                                                        }
                                                        format={(
                                                            date: string
                                                        ) =>
                                                            dayjs(date).format(
                                                                'HH:mm'
                                                            )
                                                        }
                                                        parse={(str: string) =>
                                                            dayjs(str, 'HH:mm')
                                                        }
                                                        value={week.time[1]}
                                                        placeholder={'Конец'}
                                                        onAccept={value =>
                                                            setWeekdays(prev =>
                                                                prev.map(
                                                                    (
                                                                        prevItem,
                                                                        prevIndex
                                                                    ) => {
                                                                        if (
                                                                            prevIndex ===
                                                                            index
                                                                        ) {
                                                                            return {
                                                                                ...prevItem,
                                                                                time: [
                                                                                    prevItem
                                                                                        .time[0],
                                                                                    value
                                                                                ]
                                                                            }
                                                                        }
                                                                        return prevItem
                                                                    }
                                                                )
                                                            )
                                                        }
                                                    />
                                                </Space>
                                            </Col>
                                            <Switch
                                                disabled={isAroundTheClock}
                                                defaultChecked={week.isOpen}
                                                checked={week.isOpen}
                                                onChange={isOpen =>
                                                    setWeekdays(prev =>
                                                        prev.map(
                                                            (
                                                                prevItem,
                                                                prevIndex
                                                            ) => {
                                                                if (
                                                                    prevIndex ===
                                                                    index
                                                                ) {
                                                                    return {
                                                                        ...prevItem,
                                                                        time: prevItem.time.every(
                                                                            time =>
                                                                                !time
                                                                        )
                                                                            ? [
                                                                                  '08:00',
                                                                                  '23:00'
                                                                              ]
                                                                            : prevItem.time,
                                                                        isOpen
                                                                    }
                                                                }
                                                                return prevItem
                                                            }
                                                        )
                                                    )
                                                }
                                            />
                                            <Paragraph
                                                style={{
                                                    margin: 0,
                                                    fontSize: 12,
                                                    marginLeft: 10,
                                                    cursor: 'default'
                                                }}
                                                type={
                                                    week.isOpen
                                                        ? 'success'
                                                        : 'secondary'
                                                }
                                            >
                                                {week.isOpen
                                                    ? 'Показывать'
                                                    : 'Не показывать'}
                                            </Paragraph>
                                        </Row>
                                    )
                                })}
                            </Form.Item>
                            <Checkbox
                                checked={isAroundTheClock}
                                onChange={event =>
                                    setIsAroundTheClock(event.target.checked)
                                }
                                style={{ marginBottom: 0 }}
                            >
                                {'Круглосуточно'}
                            </Checkbox>
                        </div>
                        <div
                            key={`banners-${banners.length}`}
                            className={styles.block}
                            id={'settings'}
                        >
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Настройте внешний вид объявления'}
                            </Title>
                            <Space style={{ marginBottom: 10 }}>
                                {banners.length < 5 ? (
                                    <>
                                        {isAdminOrFranchisee ? (
                                            <ImgCrop
                                                rotationSlider={false}
                                                modalTitle={'Выбрать область'}
                                                modalOk={'Выбрать'}
                                            >
                                                <Upload
                                                    className={styles.upload}
                                                    showUploadList={false}
                                                    accept={'image/*'}
                                                    multiple
                                                    style={{ marginBottom: 10 }}
                                                    disabled={false}
                                                    beforeUpload={file => {
                                                        handlePickNormalBanner(
                                                            file
                                                        )
                                                        return false
                                                    }}
                                                >
                                                    <Button
                                                        loading={
                                                            isCreateNormalBanner
                                                        }
                                                    >
                                                        {
                                                            'Загрузить свой баннер'
                                                        }
                                                    </Button>
                                                </Upload>
                                            </ImgCrop>
                                        ) : null}
                                        <Button
                                            onClick={() =>
                                                setIsModalAdBanner(true)
                                            }
                                        >
                                            {'Собрать баннер'}
                                        </Button>
                                    </>
                                ) : null}
                            </Space>
                            <br />
                            <Text>
                                {'Еще можно добавить баннеров: '}
                                <Text type={'success'}>
                                    <b>{5 - banners.length}</b>
                                </Text>
                            </Text>
                            <br />
                            {banners.length > 0 ? (
                                <Row
                                    style={{ marginTop: 10 }}
                                    gutter={[10, 10]}
                                >
                                    {banners.map((item, index) => (
                                        <Col span={6} key={`banner-${index}`}>
                                            <div
                                                className={classNames({
                                                    [styles.banner]: true,
                                                    [styles[
                                                        `banner__${item.color}`
                                                    ]]: !!item.color
                                                })}
                                            >
                                                {item.type === 'custom' ? (
                                                    <div
                                                        className={classNames({
                                                            [styles.type]: true,
                                                            [styles[
                                                                `type__${item.tag}`
                                                            ]]: true
                                                        })}
                                                    >
                                                        {
                                                            bannerTypes[
                                                                item.tag as keyof typeof bannerTypes
                                                            ]
                                                        }
                                                    </div>
                                                ) : null}
                                                <div
                                                    className={
                                                        styles.banner__nav
                                                    }
                                                >
                                                    <Space>
                                                        {item.type ===
                                                        'custom' ? (
                                                            <Button
                                                                onClick={() => {
                                                                    dispatch(
                                                                        actions.setEditBannerIndex(
                                                                            index
                                                                        )
                                                                    )
                                                                    setIsModalAdBanner(
                                                                        true
                                                                    )
                                                                }}
                                                                type={'primary'}
                                                                size={'small'}
                                                                icon={
                                                                    <EditOutlined />
                                                                }
                                                            />
                                                        ) : null}
                                                        <Button
                                                            icon={
                                                                <DeleteOutlined />
                                                            }
                                                            size={'small'}
                                                            danger={true}
                                                            style={{
                                                                backgroundColor:
                                                                    '#FFF'
                                                            }}
                                                            onClick={() =>
                                                                dispatch(
                                                                    actions.removeBanner(
                                                                        index
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    </Space>
                                                </div>
                                                {item.type === 'custom' ? (
                                                    <div
                                                        className={
                                                            styles.banner__info
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.banner__title
                                                            }
                                                        >
                                                            {item.title}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.banner__description
                                                            }
                                                        >
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div className={styles.loader}>
                                                    <Spin />
                                                </div>
                                                {item.normal_banner ? (
                                                    <TypedLazyLoadImage
                                                        loading={'lazy'}
                                                        src={
                                                            item.normal_banner
                                                                .original
                                                        }
                                                        draggable={false}
                                                    />
                                                ) : item.custom_banner ? (
                                                    <TypedLazyLoadImage
                                                        loading={'lazy'}
                                                        src={
                                                            (
                                                                item
                                                                    .custom_banner
                                                                    .thumbnails as {
                                                                    first?: string
                                                                }
                                                            )?.first ||
                                                            item.custom_banner
                                                                ?.original
                                                        }
                                                        draggable={false}
                                                    />
                                                ) : null}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Text
                                    style={{
                                        display: 'block',
                                        marginTop: 5,
                                        color: '#ff57a5'
                                    }}
                                >
                                    <b>{'Баннеры не добавлены!'}</b>
                                </Text>
                            )}
                        </div>
                        {(!isAdminOrFranchisee && typeUrl === 'url') ||
                        isAdminOrFranchisee ? (
                            <div className={styles.block} id={'register'}>
                                <Row justify={'space-between'}>
                                    <Title style={{ marginTop: 0 }} level={4}>
                                        {'Регистрация рекламы'}
                                    </Title>
                                    {typeUrl !== 'url' ? (
                                        <Button
                                            onClick={() =>
                                                setIsShowRegisterAd(
                                                    prev => !prev
                                                )
                                            }
                                            icon={
                                                isShowRegisterAd ? (
                                                    <UpCircleOutlined />
                                                ) : (
                                                    <DownCircleOutlined />
                                                )
                                            }
                                        />
                                    ) : null}
                                </Row>
                                <Collapse
                                    isOpen={
                                        isShowRegisterAd || typeUrl === 'url'
                                    }
                                    transition={
                                        'height 300ms cubic-bezier(.4, 0, .2, 1)'
                                    }
                                >
                                    <Form.Item
                                        label={
                                            <>
                                                {'ERID'}
                                                <a
                                                    style={{
                                                        marginLeft: 5
                                                    }}
                                                    href={
                                                        'https://biz.mamado.su/ord'
                                                    }
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                >
                                                    <InfoCircleOutlined
                                                        style={{
                                                            color: '#000'
                                                        }}
                                                    />
                                                </a>
                                            </>
                                        }
                                        name={'erid'}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <Input placeholder={'ERID'} />
                                    </Form.Item>
                                    <Form.Item
                                        label={'Юридическое название компании'}
                                        name={'signature'}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <Input
                                            placeholder={
                                                'Название компании, ООО Ромашка'
                                            }
                                        />
                                    </Form.Item>
                                </Collapse>
                            </div>
                        ) : null}
                        <div className={styles.block} id={'control'}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Управление'}
                            </Title>
                            {isAdminOrFranchisee ? (
                                <>
                                    <Form.Item
                                        name={'status'}
                                        label={'Статус'}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <Select
                                            style={{ width: 200 }}
                                            options={[
                                                {
                                                    value: '3',
                                                    label: 'Идут показы'
                                                },
                                                {
                                                    value: '2',
                                                    label: 'На модерации'
                                                },
                                                {
                                                    value: '1',
                                                    label: 'Черновик'
                                                },
                                                {
                                                    value: '4',
                                                    label: 'Остановлена'
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{
                                            width: '50%',
                                            marginBottom: 10
                                        }}
                                        name={'note'}
                                        label={'Внутренняя заметка'}
                                    >
                                        <TextArea
                                            placeholder={'Внутренняя заметка'}
                                        />
                                    </Form.Item>
                                    {isAdminOrFranchisee ? (
                                        <Form.Item
                                            style={{
                                                width: '50%',
                                                marginBottom: 10
                                            }}
                                            name={'admin_message'}
                                            label={'Сообщение компании'}
                                        >
                                            <TextArea
                                                placeholder={
                                                    'Сообщение компании'
                                                }
                                            />
                                        </Form.Item>
                                    ) : null}
                                    <Form.Item
                                        style={{
                                            width: '50%',
                                            marginBottom: 10
                                        }}
                                        name={'user_id'}
                                        label={'Владелец'}
                                    >
                                        <UserSelector
                                            initialValue={form.getFieldValue(
                                                'user_id'
                                            )}
                                            onChange={value =>
                                                form.setFieldValue(
                                                    'user_id',
                                                    value
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    {form.getFieldValue('user_id') ? (
                                        <div
                                            className={styles.copy}
                                            onClick={() => {
                                                const name = form
                                                    .getFieldValue('user_id')
                                                    .split('{d}')[0]
                                                if (name) {
                                                    copy(name)
                                                    message.success(
                                                        'Владелец был скопирован'
                                                    )
                                                }
                                            }}
                                        >
                                            {'Скопировать владельца'}
                                        </div>
                                    ) : null}
                                    {profile &&
                                    getRole(profile.roles) === 'admin' ? (
                                        <Form.Item
                                            style={{
                                                marginBottom: 10,
                                                width: '50%'
                                            }}
                                            name={'franchisee_id'}
                                            label={'Франчайзи'}
                                        >
                                            <FranchiseeSelector
                                                initialValue={form.getFieldValue(
                                                    'franchisee_id'
                                                )}
                                                onChange={value =>
                                                    form.setFieldValue(
                                                        'franchisee_id',
                                                        value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    ) : null}
                                </>
                            ) : null}
                            <Form.Item
                                name={'payment_strategy'}
                                label={'Вид списаний'}
                                style={{
                                    width: '50%',
                                    marginBottom: 10
                                }}
                            >
                                <Select
                                    options={[
                                        {
                                            label: 'Оплата за клики',
                                            value: 'click'
                                        },
                                        {
                                            label: 'Оплата за 1000 показов',
                                            value: 'view'
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name={
                                    paymentStrategyWatch === 'click'
                                        ? 'click_cost'
                                        : 'view_cost'
                                }
                                label={
                                    paymentStrategyWatch === 'click'
                                        ? 'Стоимость клика'
                                        : 'Стоимость за 1000 показов'
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Заполните стоимость'
                                    }
                                ]}
                            >
                                <InputNumber
                                    disabled={!isAdminOrFranchisee}
                                    addonAfter={'₽'}
                                    style={{ width: 200 }}
                                    placeholder={
                                        paymentStrategyWatch === 'click'
                                            ? 'Стоимость клика'
                                            : 'Стоимость за 1000 показов'
                                    }
                                    onKeyDown={event => {
                                        if (
                                            !/[0-9]/.test(event.key) &&
                                            event.key !== 'Backspace'
                                        ) {
                                            event.preventDefault()
                                        }
                                    }}
                                />
                            </Form.Item>
                            <div style={{ color: '#777', marginTop: 10 }}>
                                {
                                    'После модерации стоимость будет уточнена, согласно настройкам рекламной кампании и геолокации.'
                                }
                                <br />
                                {
                                    'Цена за клики в пределах 35 - 200 руб. за клик, стоимость за 1000  показов от 35 - 200 руб.'
                                }
                            </div>
                        </div>
                        {isDraft ? (
                            <>
                                <span className={styles.label}>
                                    {
                                        'Для отправки кампании на модерацию, необходимо заполнить поля:'
                                    }
                                </span>
                                <ul className={styles.list}>
                                    {!isDistanceFilled ? (
                                        <li>
                                            {distance === 'region'
                                                ? regions.length === 0
                                                    ? 'Зона показа рекламы'
                                                    : ''
                                                : selectedRadius === 0
                                                  ? 'Радиус'
                                                  : ''}
                                        </li>
                                    ) : null}
                                    {(
                                        typeUrl === 'url'
                                            ? !urlWatch
                                            : !companySelector
                                    ) ? (
                                        <li>
                                            {typeUrl === 'url'
                                                ? 'Ссылка на рекламируемую страницу'
                                                : 'Ссылка на компанию'}
                                        </li>
                                    ) : null}
                                    {!isAdminOrFranchisee &&
                                    typeUrl === 'url' &&
                                    !emailWatch ? (
                                        <li>{'Почта для выставления счета'}</li>
                                    ) : null}
                                    {!periodWatch ? (
                                        <li>{'Срок проведения кампании'}</li>
                                    ) : null}
                                    {!isScheduleFilled && !isAroundTheClock ? (
                                        <li>{'Расписание показов'}</li>
                                    ) : null}
                                    {!isCategoriesFilled ? (
                                        <li>{'Интересы аудиторий'}</li>
                                    ) : null}
                                    {banners.length === 0 ? (
                                        <li>{'Баннеры'}</li>
                                    ) : null}
                                </ul>
                            </>
                        ) : null}
                        <Row justify={'end'}>
                            <Button
                                loading={isProcess || isFetchingCategories}
                                htmlType={'submit'}
                                type={'primary'}
                                icon={
                                    isCreate ? (
                                        <PlusOutlined />
                                    ) : (
                                        <EditOutlined />
                                    )
                                }
                            >
                                {isCreate
                                    ? 'Создать кампанию'
                                    : 'Сохранить изменения'}
                            </Button>
                        </Row>
                    </Form>
                </Col>
                <Col span={6}></Col>
            </Row>
            <AreaSelector
                showModal={isRegionModal}
                onClose={() => setIsRegionModal(false)}
                onAdd={handleAddRegion}
            />
            <ModalAdvertisingRadiusModal
                address={address}
                selectedRadius={selectedRadius}
                setSelectedRadius={setSelectedRadius}
                isModalRadius={isModalRadius}
                onClose={() => setIsModalRadius(false)}
                handleSaveRadius={() => setIsModalRadius(false)}
                setAddress={setAddress}
            />
            <AdBannerModal
                showModal={isModalAdBanner}
                onClose={() => setIsModalAdBanner(false)}
                onAddBanner={form => dispatch(actions.addBanner(form))}
            />
            <Modal
                open={isCreateInfoModal}
                title={
                    <>
                        <CheckOutlined
                            style={{ color: 'green', marginRight: 6 }}
                        />
                        {'Кампания была успешно создана'}
                    </>
                }
                cancelText={'Понятно'}
                cancelButtonProps={{ type: 'primary' }}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={() => navigate('/advertising')}
            >
                <p>{'Кампания будет запущена после прохождения модерации'}</p>
            </Modal>
            <Modal
                open={isModalExit}
                title={
                    <>
                        <ExclamationCircleOutlined
                            style={{ color: '#faad14', marginRight: 6 }}
                        />
                        {'Внимание'}
                    </>
                }
                okText={'Все равно перейти'}
                cancelText={'Остаться'}
                onCancel={() => {
                    setIsModalExit(false)
                    if (blocker.reset) {
                        blocker.reset()
                    }
                }}
                onOk={() => {
                    setIsModalExit(false)
                    if (blocker.proceed) {
                        blocker.proceed()
                    }
                }}
            >
                <Paragraph>
                    {
                        'У вас остались не сохраненные изменения, все равно перейти на другую страницу?'
                    }
                </Paragraph>
            </Modal>
        </>
    )
}
