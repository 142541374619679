import type { ReactElement, FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import type { Swiper as SwiperClass } from 'swiper/types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import type { LazyLoadImageProps } from 'react-lazy-load-image-component'

const TypedLazyLoadImage = LazyLoadImage as FC<LazyLoadImageProps>

// Styles
import styles from './styles.module.scss'

interface Props {
    isVisible: boolean | number
    onClose: () => void
    images: { url: string }[]
}

export default function GalleryModal({
    isVisible,
    onClose,
    images = []
}: Props): ReactElement {
    const [generalSlider, setGeneralSlider] = useState({
        left: false,
        right: true
    })
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null)

    const refGeneralSwiper = useRef<SwiperRef>(null)

    const handlePressESC = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            onClose()
        }
    }

    useEffect(() => {
        const $body = document.querySelector('body')
        if ($body) {
            $body.style.overflowY = isVisible ? 'hidden' : 'auto'
        }

        document.addEventListener('keydown', handlePressESC)

        if (isVisible === false) {
            document.removeEventListener('keydown', handlePressESC)
        }

        if (typeof isVisible === 'number' && refGeneralSwiper.current) {
            refGeneralSwiper.current.swiper.slideReset(0)
            setTimeout(
                () => refGeneralSwiper.current!.swiper.slideTo(isVisible, 0),
                10
            )
        }
    }, [isVisible])

    const generalSlide = (type: 'prev' | 'next'): void => {
        if (refGeneralSwiper.current) {
            if (type === 'prev') {
                refGeneralSwiper.current.swiper.slidePrev()
            } else {
                refGeneralSwiper.current.swiper.slideNext()
            }
        }
    }

    return (
        <div
            style={{
                display:
                    typeof isVisible === 'number' || isVisible ? 'flex' : 'none'
            }}
            className={styles.modal}
        >
            <div className={styles.modal__header}>
                <h5>{'Фото'}</h5>
                <button onClick={onClose}></button>
            </div>
            <div className={styles.modal__container}>
                {generalSlider.left && images.length > 1 ? (
                    <button
                        onClick={() => generalSlide('prev')}
                        className={styles['modal__left-arrow']}
                    />
                ) : null}
                {generalSlider.right && images.length > 1 ? (
                    <button
                        onClick={() => generalSlide('next')}
                        className={styles['modal__right-arrow']}
                    />
                ) : null}
                <Swiper
                    ref={refGeneralSwiper}
                    thumbs={{ swiper: thumbsSwiper }}
                    spaceBetween={5}
                    modules={[FreeMode, Thumbs]}
                    onSlideChange={({ isBeginning, isEnd }) =>
                        setGeneralSlider({ left: !isBeginning, right: !isEnd })
                    }
                    className={'swiperStretchSliders'}
                >
                    {images.map((item, index) => (
                        <SwiperSlide
                            className={styles['modal__image-container']}
                            key={`gallery-${index}`}
                        >
                            <TypedLazyLoadImage
                                className={styles.modal__image}
                                src={item.url}
                                width={770}
                                height={400}
                                alt={'Фото'}
                                sizes={
                                    '(max-width: 768px) 100vw, (max-width: 1200px) 50vw'
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className={styles.modal__container}>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    modules={[FreeMode, Navigation, Thumbs]}
                    spaceBetween={8}
                    slidesPerView={'auto'}
                    freeMode={true}
                    watchSlidesProgress={true}
                    className={'thumbnailsSwiper'}
                >
                    {images.map((item, index) => (
                        <SwiperSlide
                            key={`thumb-${index}`}
                            className={styles.modal__thumb}
                        >
                            <TypedLazyLoadImage
                                src={item.url}
                                width={120}
                                height={120}
                                alt={'Фото'}
                                sizes={
                                    '(max-width: 768px) 100vw, (max-width: 1200px) 50vw'
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
