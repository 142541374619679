import type { Key, ReactElement, FC } from 'react'
import {
    Typography,
    Table,
    Pagination,
    Row,
    Button,
    Col,
    Space,
    message
} from 'antd'
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloudDownloadOutlined,
    DeliveredProcedureOutlined,
    DownCircleOutlined,
    PlusOutlined,
    UpCircleOutlined,
    UsbOutlined,
    WarningOutlined
} from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import CompanyFilter from '../../components/CompanyFilter'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks/useAppSelector'
import { actions } from './slice'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { companyTypes, getRole, ignoreKeys } from '../../utils/helpers'
import type { ICompany } from './types'
import styles from './styles.module.scss'
import Collapse from '@kunukn/react-collapse'
import { authorizationSelector } from '../Authorization/selectors'
import dayjs from 'dayjs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { companiesSelector } from './selectors'
import type { LazyLoadImageProps } from 'react-lazy-load-image-component'

const TypedLazyLoadImage = LazyLoadImage as FC<LazyLoadImageProps>

const { Title, Paragraph } = Typography

const columns: ColumnsType<ICompany> = [
    {
        title: 'Фото',
        key: 'main_image_thumbnail',
        width: 250,
        render: (data: ICompany) => (
            <Link
                className={styles['image-link']}
                to={`/companies/${data.company_id}`}
                target={'_blank'}
            >
                <TypedLazyLoadImage
                    src={
                        data.preview_thumbnails?.wide
                            ? data.preview_thumbnails.wide
                            : data.main_image_thumbnail
                    }
                    className={styles.image}
                />
            </Link>
        )
    },
    {
        title: 'Наименование',
        dataIndex: '',
        key: 'name',
        width: 200,
        render: (data: ICompany) => (
            <Link
                className={styles.link}
                to={`/companies/${data.company_id}`}
                target={'_blank'}
            >
                {data.name || 'Название не заполнено'}
            </Link>
        )
    },
    {
        title: 'Категории',
        dataIndex: '',
        key: 'categories',
        width: 150,
        render: (data: ICompany) =>
            data.companyCategories.length > 0 ? (
                <div style={{ fontSize: 12 }}>
                    {data.companyCategories
                        .map(category => category.full_name)
                        .join(', ')}
                </div>
            ) : (
                'Не выбраны'
            )
    },
    {
        title: 'Адрес',
        dataIndex: 'text_address',
        key: 'text_address',
        width: 200,
        render: value =>
            value || (
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                    {'Адрес не указан'}
                </span>
            )
    },
    {
        title: 'Владелец',
        dataIndex: 'businessAccount',
        key: 'businessAccount',
        align: 'center',
        width: 100,
        render: value => (
            <div
                style={{
                    color: value ? 'black' : 'silver',
                    whiteSpace: 'nowrap'
                }}
            >
                {value ? <CheckOutlined /> : '—'}
            </div>
        )
    },
    {
        title: 'Компания в архиве',
        dataIndex: 'published',
        key: 'published',
        align: 'center',
        width: 150,
        render: value => (value ? 'Нет' : 'Да')
    },
    {
        title: 'Черновик',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 100,
        render: value => (value === 'draft' ? 'Да' : 'Нет')
    },
    {
        title: 'Описание',
        dataIndex: 'about',
        key: 'about',
        align: 'center',
        width: 100,
        render: value => (
            <div style={{ color: !value ? 'silver' : 'inherit' }}>
                {value ? <CheckOutlined /> : '—'}
            </div>
        )
    },
    {
        title: 'Статус публикации',
        dataIndex: 'cached_displayed',
        key: 'cached_displayed',
        align: 'center',
        width: 200,
        render: value => (
            <b style={{ color: value ? 'green' : 'red' }}>
                {value ? 'Опубликовано' : 'Не опубликовано'}
            </b>
        )
    },
    {
        title: 'Блокировка',
        dataIndex: 'status',
        key: 'blocked',
        align: 'center',
        width: 200,
        render: value => (
            <b style={{ color: value !== 'active' ? 'red' : 'green' }}>
                {value !== 'active' ? 'Заблокирован' : 'Не заблокирован'}
            </b>
        )
    },
    {
        title: 'Филиал',
        dataIndex: 'is_filial',
        key: 'is_filial',
        align: 'center',
        render: value => <b>{value ? 'Да' : 'Нет'}</b>
    },
    {
        title: 'Тип компании',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 150,
        render: (value: keyof typeof companyTypes) => (
            <b>{companyTypes[value]}</b>
        )
    },
    {
        title: 'Тариф активен до',
        key: 'tariff',
        align: 'center',
        render: (data: ICompany) =>
            data.activePlanSubscription ? (
                <span style={{ color: 'green' }}>
                    {dayjs(data.activePlanSubscription.endsAt).format(
                        'DD.MM.YYYY HH:mm'
                    )}
                </span>
            ) : (
                <span style={{ color: 'red' }}>{'Не активен'}</span>
            )
    }
]

const filterColumns: Key[] = [
    'categories',
    'businessAccount',
    'blocked',
    'published',
    'status',
    'about',
    'is_filial'
]

export default function Companies(): ReactElement {
    const [showFilter, setShowFilter] = useState(false)
    const [selectedCompanies, setSelectedCompanies] = useState<Key[]>([])

    const [searchParams, setSearchParams] = useSearchParams()

    const pageQuery = searchParams.get('page')
    const pageSizeQuery = searchParams.get('pageSize')

    const page = pageQuery ? parseInt(pageQuery) : 1
    const pageSize = pageSizeQuery ? parseInt(pageSizeQuery) : 10

    const { profile } = useAppSelector(authorizationSelector)
    const isUser = profile ? getRole(profile.roles) === 'user' : true
    const isAdminOrFranchisee = profile
        ? getRole(profile.roles) === 'admin' ||
          getRole(profile.roles) === 'franchisee'
        : false

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const [messageApi, contextHolder] = message.useMessage()

    const {
        data,
        meta,
        isFetching,
        isProcessChangeMultiplePublishing,
        isProcessChangeMultipleBlocking,
        isProcessChangeMultipleParams,
        isDownloadExcel
    } = useAppSelector(companiesSelector)

    useEffect(() => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.fetch({
                page,
                pageSize,
                filter
            })
        )

        setSearchParams({
            ...filter,
            ...(page !== 1 ? { page: page.toString() } : {}),
            ...(pageSize !== 10 ? { pageSize: pageSize.toString() } : {})
        })
    }, [searchParams])

    const handleChangePagination = (
        pageValue?: number,
        pageSizeValue?: number
    ) => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        setSearchParams({
            ...filter,
            ...(pageValue && pageValue !== 1
                ? { page: pageValue.toString() }
                : {}),
            ...(pageSizeValue && pageSizeValue !== 10
                ? { pageSize: pageSizeValue.toString() }
                : {})
        })
        setSelectedCompanies([])
    }

    const checkSelectedCompanies = (published: boolean) =>
        data.some(
            company =>
                company.published === published &&
                selectedCompanies.includes(company.company_id)
        )

    const checkBlockedCompanies = (blocked: boolean) =>
        data.some(
            company =>
                company.status === (blocked ? 'inactive' : 'active') &&
                selectedCompanies.includes(company.company_id)
        )

    const checkDisplayInCatalog = (displayed: boolean) =>
        data.some(
            company =>
                company.display_in_catalog === displayed &&
                selectedCompanies.includes(company.company_id)
        )

    const checkAvailableForDeletion = (available: boolean) =>
        data.some(
            company =>
                company.available_for_deletion === available &&
                selectedCompanies.includes(company.company_id)
        )

    const handleChangeMultiplePublished = (published: boolean) => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.changeMultiplePublished({
                company_ids: selectedCompanies,
                published,
                page,
                pageSize,
                filter
            })
        )

        setSelectedCompanies([])

        messageApi.open({
            type: 'info',
            content: 'Изменения вступят в силу, через 5 минут'
        })
    }

    const handleChangeMultipleBlocked = (blocked: boolean) => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.changeMultipleBlocked({
                company_ids: selectedCompanies,
                blocked,
                page,
                pageSize,
                filter
            })
        )

        setSelectedCompanies([])

        messageApi.open({
            type: 'info',
            content: 'Изменения вступят в силу, через 5 минут'
        })
    }

    const handleChangeMultipleParams = (key: string, value: unknown) => {
        const filter: Record<string, string> = {}

        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })

        dispatch(
            actions.changeMultipleParams({
                company_ids: selectedCompanies,
                form: {
                    [key]: value
                },
                page,
                pageSize,
                filter
            })
        )

        setSelectedCompanies([])

        messageApi.open({
            type: 'info',
            content: 'Изменения вступят в силу, через 5 минут'
        })
    }

    const handleDownloadExcel = () => {
        const filter: Record<string, string> = {}
        searchParams.forEach((value, key) => {
            if (ignoreKeys.includes(key)) {
                return
            }
            filter[key] = value
        })
        dispatch(actions.downloadExcel({ page, pageSize, filter }))
    }

    return (
        <>
            <Row justify={'end'} style={{ marginBottom: 20 }}>
                <Row>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => navigate('/companies/create')}
                        type={'primary'}
                    >
                        {'Создать компанию'}
                    </Button>
                </Row>
            </Row>
            <div style={{ marginBottom: 25 }} className={styles.container}>
                <Row justify={'space-between'}>
                    <Title level={3} style={{ margin: 0 }}>
                        {'Поиск компаний'}
                    </Title>
                    <Row>
                        <Button
                            style={{ width: 220 }}
                            onClick={() => setShowFilter(prev => !prev)}
                            icon={
                                showFilter ? (
                                    <UpCircleOutlined />
                                ) : (
                                    <DownCircleOutlined />
                                )
                            }
                        >
                            {showFilter ? 'Скрыть фильтр' : 'Показать фильтр'}
                        </Button>
                    </Row>
                </Row>
                <Collapse
                    isOpen={showFilter}
                    transition={'height 300ms cubic-bezier(.4, 0, .2, 1)'}
                >
                    <div style={{ height: 25 }} />
                    <CompanyFilter />
                </Collapse>
            </div>
            <div className={styles.container}>
                {!isUser ? (
                    <Row justify={'space-between'}>
                        <Col span={20}>
                            <Row align={'middle'} wrap={true}>
                                <Space style={{ marginBottom: 15 }} wrap={true}>
                                    <Button
                                        type={'primary'}
                                        disabled={
                                            !checkSelectedCompanies(false)
                                        }
                                        onClick={() =>
                                            handleChangeMultiplePublished(true)
                                        }
                                        icon={<UsbOutlined />}
                                    >
                                        {'Восстановить'}
                                    </Button>
                                    <Button
                                        disabled={!checkSelectedCompanies(true)}
                                        type={'primary'}
                                        onClick={() =>
                                            handleChangeMultiplePublished(false)
                                        }
                                        icon={<DeliveredProcedureOutlined />}
                                    >
                                        {'Архивировать'}
                                    </Button>
                                    <div
                                        style={{
                                            width: 1,
                                            height: 25,
                                            background: '#eee9fa'
                                        }}
                                    />
                                    <Button
                                        type={'primary'}
                                        icon={<CheckCircleOutlined />}
                                        disabled={!checkBlockedCompanies(true)}
                                        onClick={() =>
                                            handleChangeMultipleBlocked(false)
                                        }
                                    >
                                        {'Разблокировать'}
                                    </Button>
                                    <Button
                                        type={'primary'}
                                        icon={<WarningOutlined />}
                                        disabled={!checkBlockedCompanies(false)}
                                        onClick={() =>
                                            handleChangeMultipleBlocked(true)
                                        }
                                    >
                                        {'Заблокировать'}
                                    </Button>
                                    {isAdminOrFranchisee ? (
                                        <>
                                            <div
                                                style={{
                                                    width: 1,
                                                    height: 25,
                                                    background: '#eee9fa'
                                                }}
                                            />
                                            <Row align={'middle'}>
                                                <div
                                                    style={{
                                                        width: 'max-content',
                                                        marginRight: 10
                                                    }}
                                                >
                                                    {'Показывать в каталоге:'}
                                                </div>
                                                <Button
                                                    disabled={
                                                        !checkDisplayInCatalog(
                                                            false
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleChangeMultipleParams(
                                                            'display_in_catalog',
                                                            true
                                                        )
                                                    }
                                                >
                                                    {'Да'}
                                                </Button>
                                                <Button
                                                    disabled={
                                                        !checkDisplayInCatalog(
                                                            true
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleChangeMultipleParams(
                                                            'display_in_catalog',
                                                            false
                                                        )
                                                    }
                                                    style={{ marginLeft: 7 }}
                                                >
                                                    {'Нет'}
                                                </Button>
                                            </Row>
                                            <div
                                                style={{
                                                    width: 1,
                                                    height: 25,
                                                    background: '#eee9fa'
                                                }}
                                            />
                                            <Row align={'middle'}>
                                                <div
                                                    style={{
                                                        width: 'max-content',
                                                        marginRight: 10
                                                    }}
                                                >
                                                    {'Разрешить удалять:'}
                                                </div>
                                                <Button
                                                    disabled={
                                                        !checkAvailableForDeletion(
                                                            false
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleChangeMultipleParams(
                                                            'available_for_deletion',
                                                            true
                                                        )
                                                    }
                                                >
                                                    {'Да'}
                                                </Button>
                                                <Button
                                                    disabled={
                                                        !checkAvailableForDeletion(
                                                            true
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleChangeMultipleParams(
                                                            'available_for_deletion',
                                                            false
                                                        )
                                                    }
                                                    style={{ marginLeft: 7 }}
                                                >
                                                    {'Нет'}
                                                </Button>
                                            </Row>
                                        </>
                                    ) : null}
                                    {selectedCompanies.length ? (
                                        <div
                                            style={{
                                                width: 'max-content',
                                                color: '#000'
                                            }}
                                        >
                                            {'Выбрано: '}
                                            {selectedCompanies.length}
                                        </div>
                                    ) : null}
                                </Space>
                            </Row>
                        </Col>
                        <Col
                            span={4}
                            style={{ display: 'flex', justifyContent: 'end' }}
                        >
                            <Button
                                loading={isDownloadExcel}
                                onClick={handleDownloadExcel}
                                type={'primary'}
                                icon={<CloudDownloadOutlined />}
                            >
                                {'Скачать (.xlsx)'}
                            </Button>
                        </Col>
                    </Row>
                ) : null}
                <Table
                    dataSource={data}
                    columns={columns.filter(column =>
                        isUser && column.key
                            ? !filterColumns.includes(column.key)
                            : true
                    )}
                    rowKey={'company_id'}
                    scroll={isUser ? undefined : { x: 2100 }}
                    loading={
                        isFetching ||
                        isProcessChangeMultiplePublishing ||
                        isProcessChangeMultipleBlocking ||
                        isProcessChangeMultipleParams
                    }
                    pagination={false}
                    sticky={{ offsetHeader: 0 }}
                    rowSelection={
                        !isUser
                            ? {
                                  selectedRowKeys: selectedCompanies,
                                  onChange: setSelectedCompanies
                              }
                            : undefined
                    }
                    footer={() => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Pagination
                                showQuickJumper
                                current={page}
                                pageSize={pageSize}
                                defaultCurrent={1}
                                defaultPageSize={pageSize}
                                total={meta?.total}
                                onChange={handleChangePagination}
                            />
                        </div>
                    )}
                />
                <Paragraph type={'secondary'} style={{ marginTop: 15 }}>
                    {'Данные в таблице обновляются каждые 5 минут'}
                </Paragraph>
            </div>
            {contextHolder}
        </>
    )
}
