import { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorWrapper from './components/ErrorWrapper'

if (
    process.env.APP_ENV === 'production' &&
    process.env.APP_SENTRY_DSN != null
) {
    Sentry.init({
        dsn: process.env.APP_SENTRY_DSN,
        environment: process.env.APP_ENV,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: ['Network Error', 'Request failed with status code 401']
    })
}

const $rootElement = document.getElementById('root') as HTMLElement

if ($rootElement) {
    ReactDOM.createRoot($rootElement).render(
        <ErrorBoundary
            fallback={<ErrorWrapper />}
            onError={error => Sentry.captureException(error)}
        >
            <App />
        </ErrorBoundary>
    )
}
