import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Form, Input, Modal } from 'antd'
import type { ITag } from '../../containers/SettingsCompaniesTags/types.ts'
import { useForm } from 'antd/es/form/Form'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsCompaniesTags/slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsCompaniesTagsSelector } from '../../containers/SettingsCompaniesTags/selectors.ts'

interface Props {
    selectedTag: ITag | undefined
    onAfterClose: () => void
    postCategoryId: string
}

export default function CompanyTagWizardModal({
    selectedTag,
    onAfterClose,
    postCategoryId
}: Props): ReactElement {
    const dispatch = useDispatch()

    const [form] = useForm()

    const { isModalTagWizard, isCreateTagProcess, isEditTagProcess } =
        useAppSelector(settingsCompaniesTagsSelector)

    useEffect(() => {
        if (selectedTag) {
            form.setFieldsValue(selectedTag)
        } else {
            form.resetFields()
        }
    }, [selectedTag])

    const handleSendForm = (form: Record<string, unknown>) => {
        if (selectedTag) {
            dispatch(
                actions.editTag({
                    company_tag_id: selectedTag.company_tag_id,
                    form: { ...form, company_category_id: postCategoryId }
                })
            )
        } else {
            dispatch(
                actions.createTag({
                    form: { ...form, company_category_id: postCategoryId }
                })
            )
        }
    }

    return (
        <Modal
            open={isModalTagWizard}
            title={`${selectedTag ? 'Редактирование' : 'Создание'} тега для категории компаний`}
            onCancel={() => {
                setTimeout(onAfterClose, 200)
                dispatch(actions.toggleTagWizardModal(false))
            }}
            okText={selectedTag ? 'Редактировать' : 'Создать'}
            onOk={form.submit}
            okButtonProps={{
                loading: isCreateTagProcess || isEditTagProcess
            }}
        >
            <Form layout={'vertical'} form={form} onFinish={handleSendForm}>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'title'}
                    label={'Название тега'}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите название тега!'
                        }
                    ]}
                >
                    <Input placeholder={'Название тега'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'slug'}
                    label={'Слаг тега'}
                    normalize={value =>
                        value
                            .replace(/[^a-zA-Z0-9-]+/g, '-')
                            .replace(/--+/g, '-')
                            .replace(/^-+/g, '')
                            .toLowerCase()
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите слаг тега!'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Слаг тега'}
                        onBlur={() => {
                            const value = form.getFieldValue('slug')
                            if (typeof value === 'string') {
                                form.setFieldValue(
                                    'slug',
                                    value.replace(/-$/, '')
                                )
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'meta_h1'}
                    label={'H1 заголовок категории'}
                >
                    <Input placeholder={'H1 заголовок категории'} />
                </Form.Item>
                {/*<Form.Item*/}
                {/*    style={{ marginBottom: 10 }}*/}
                {/*    name={'description'}*/}
                {/*    label={'Описание категории'}*/}
                {/*>*/}
                {/*    <Input.TextArea placeholder={'Описание категории'} />*/}
                {/*</Form.Item>*/}
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'meta_title'}
                    label={'Meta-title'}
                >
                    <Input placeholder={'Meta-title'} />
                </Form.Item>
                <Form.Item name={'meta_description'} label={'Meta-description'}>
                    <Input.TextArea placeholder={'Meta-description'} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
